<script lang="ts">
	// let stars: string[] = [];
	// let amount = 100;
	// for (let i = 0; i <= amount; i++) {
	// 	let size = Math.ceil(Math.random() * 3) + 'px';
	// 	let left = Math.ceil(Math.random() * 100) + '%';
	// 	let top = Math.ceil(Math.random() * 100) + '%';
	// 	stars.push(`w-[${size}] h-[${size}] left-[${left}] top-[${top}]`);
	// }
	let stars2 = [
		'w-[2px] h-[2px] left-[59%] top-[25%]',
		'w-[1px] h-[1px] left-[26%] top-[30%]',
		'w-[3px] h-[3px] left-[81%] top-[47%]',
		'w-[2px] h-[2px] left-[55%] top-[55%]',
		'w-[3px] h-[3px] left-[36%] top-[71%]',
		'w-[3px] h-[3px] left-[70%] top-[38%]',
		'w-[3px] h-[3px] left-[41%] top-[90%]',
		'w-[1px] h-[1px] left-[95%] top-[68%]',
		'w-[3px] h-[3px] left-[90%] top-[83%]',
		'w-[3px] h-[3px] left-[52%] top-[81%]',
		'w-[2px] h-[2px] left-[35%] top-[54%]',
		'w-[1px] h-[1px] left-[9%] top-[84%]',
		'w-[2px] h-[2px] left-[41%] top-[74%]',
		'w-[2px] h-[2px] left-[99%] top-[12%]',
		'w-[1px] h-[1px] left-[62%] top-[81%]',
		'w-[1px] h-[1px] left-[82%] top-[29%]',
		'w-[2px] h-[2px] left-[24%] top-[28%]',
		'w-[2px] h-[2px] left-[28%] top-[36%]',
		'w-[2px] h-[2px] left-[7%] top-[97%]',
		'w-[3px] h-[3px] left-[29%] top-[52%]',
		'w-[1px] h-[1px] left-[69%] top-[81%]',
		'w-[1px] h-[1px] left-[22%] top-[100%]',
		'w-[1px] h-[1px] left-[9%] top-[35%]',
		'w-[1px] h-[1px] left-[86%] top-[68%]',
		'w-[2px] h-[2px] left-[42%] top-[98%]',
		'w-[1px] h-[1px] left-[54%] top-[64%]',
		'w-[3px] h-[3px] left-[14%] top-[12%]',
		'w-[2px] h-[2px] left-[78%] top-[86%]',
		'w-[3px] h-[3px] left-[100%] top-[12%]',
		'w-[2px] h-[2px] left-[59%] top-[36%]',
		'w-[3px] h-[3px] left-[4%] top-[36%]',
		'w-[2px] h-[2px] left-[55%] top-[26%]',
		'w-[3px] h-[3px] left-[68%] top-[16%]',
		'w-[1px] h-[1px] left-[67%] top-[66%]',
		'w-[1px] h-[1px] left-[47%] top-[49%]',
		'w-[1px] h-[1px] left-[14%] top-[59%]',
		'w-[1px] h-[1px] left-[95%] top-[76%]',
		'w-[2px] h-[2px] left-[99%] top-[12%]',
		'w-[2px] h-[2px] left-[33%] top-[5%]',
		'w-[3px] h-[3px] left-[5%] top-[9%]',
		'w-[2px] h-[2px] left-[81%] top-[18%]',
		'w-[2px] h-[2px] left-[92%] top-[10%]',
		'w-[2px] h-[2px] left-[33%] top-[70%]',
		'w-[3px] h-[3px] left-[92%] top-[54%]',
		'w-[3px] h-[3px] left-[79%] top-[90%]',
		'w-[2px] h-[2px] left-[12%] top-[28%]',
		'w-[3px] h-[3px] left-[15%] top-[22%]',
		'w-[2px] h-[2px] left-[19%] top-[56%]',
		'w-[1px] h-[1px] left-[66%] top-[41%]',
		'w-[3px] h-[3px] left-[73%] top-[20%]',
		'w-[2px] h-[2px] left-[27%] top-[39%]',
		'w-[3px] h-[3px] left-[88%] top-[72%]',
		'w-[1px] h-[1px] left-[29%] top-[67%]',
		'w-[1px] h-[1px] left-[63%] top-[36%]',
		'w-[3px] h-[3px] left-[83%] top-[83%]',
		'w-[1px] h-[1px] left-[9%] top-[38%]',
		'w-[3px] h-[3px] left-[35%] top-[21%]',
		'w-[2px] h-[2px] left-[77%] top-[57%]',
		'w-[2px] h-[2px] left-[100%] top-[87%]',
		'w-[2px] h-[2px] left-[5%] top-[92%]',
		'w-[1px] h-[1px] left-[58%] top-[19%]',
		'w-[1px] h-[1px] left-[3%] top-[6%]',
		'w-[2px] h-[2px] left-[15%] top-[66%]',
		'w-[2px] h-[2px] left-[55%] top-[84%]',
		'w-[1px] h-[1px] left-[74%] top-[55%]',
		'w-[1px] h-[1px] left-[93%] top-[25%]',
		'w-[1px] h-[1px] left-[81%] top-[92%]',
		'w-[2px] h-[2px] left-[87%] top-[94%]',
		'w-[2px] h-[2px] left-[95%] top-[24%]',
		'w-[1px] h-[1px] left-[51%] top-[54%]',
		'w-[2px] h-[2px] left-[37%] top-[4%]',
		'w-[1px] h-[1px] left-[68%] top-[66%]',
		'w-[3px] h-[3px] left-[34%] top-[58%]',
		'w-[2px] h-[2px] left-[1%] top-[54%]',
		'w-[1px] h-[1px] left-[75%] top-[26%]',
		'w-[2px] h-[2px] left-[81%] top-[6%]',
		'w-[3px] h-[3px] left-[61%] top-[57%]',
		'w-[2px] h-[2px] left-[16%] top-[72%]',
		'w-[2px] h-[2px] left-[12%] top-[73%]',
		'w-[1px] h-[1px] left-[25%] top-[13%]',
		'w-[3px] h-[3px] left-[96%] top-[93%]',
		'w-[3px] h-[3px] left-[24%] top-[91%]',
		'w-[1px] h-[1px] left-[45%] top-[23%]',
		'w-[2px] h-[2px] left-[2%] top-[100%]',
		'w-[2px] h-[2px] left-[51%] top-[27%]',
		'w-[3px] h-[3px] left-[63%] top-[75%]',
		'w-[1px] h-[1px] left-[11%] top-[94%]',
		'w-[1px] h-[1px] left-[14%] top-[91%]',
		'w-[3px] h-[3px] left-[32%] top-[37%]',
		'w-[3px] h-[3px] left-[21%] top-[85%]',
		'w-[1px] h-[1px] left-[20%] top-[77%]',
		'w-[1px] h-[1px] left-[38%] top-[70%]',
		'w-[1px] h-[1px] left-[35%] top-[67%]',
		'w-[1px] h-[1px] left-[78%] top-[47%]',
		'w-[1px] h-[1px] left-[79%] top-[16%]',
		'w-[3px] h-[3px] left-[42%] top-[91%]',
		'w-[1px] h-[1px] left-[30%] top-[75%]',
		'w-[3px] h-[3px] left-[65%] top-[78%]',
		'w-[2px] h-[2px] left-[22%] top-[14%]',
		'w-[1px] h-[1px] left-[83%] top-[46%]',
		'w-[3px] h-[3px] left-[5%] top-[81%]'
	];
</script>

<div class="w-full h-full overflow-hidden">
	<div class="relative w-full min-w-[1440px] h-full animate-stars">
		{#each stars2 as star}
			<div class="absolute {star} blur-[2px] bg-white" />
		{/each}
	</div>
	<div class="relative w-full min-w-[1440px] h-full animate-stars">
		{#each stars2 as star}
			<div class="absolute {star} blur-[2px] bg-white" />
		{/each}
	</div>
</div>
